import React, { useState, useCallback, useEffect } from "react";
import { ref, getDownloadURL, uploadString } from "firebase/storage";
import { useFirebase } from "../../../../../../FirebaseContext/FirebaseContext";
import defaultMaterialDB from "../../../../../../../assets/defaultMaterialDB.json";
import Toggle from "../../../../../Toggle";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import StorageOutlinedIcon from "@mui/icons-material/StorageOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import MaterialDetailsDialog from "./Materials/MaterialDetailsDialog";
import MaterialTable from "./Materials/MaterialTable";
import styles from "../../../../../ScientificDashboards.module.css";

const MaterialEditForm = ({
    open,
    handleClose,
    materialDB,
    setMaterialDB,
    selectedMaterial,
}) => {
    const [material, setMaterial] = useState({ ...selectedMaterial });

    const handleMaterialChange = (event) => {
        const { name, value } = event.target;
        setMaterial((prevMaterial) => ({
            ...prevMaterial,
            [name]: value,
        }));
    };

    const handlePropertyTableChange = (name, index, field, value) => {
        setMaterial((prevMaterial) => {
            const newValues = prevMaterial[name].values
                .split(";")
                .map((pair, pairIndex) => {
                    if (index === pairIndex) {
                        const values = pair.split(",");
                        values[field] = value;
                        return values.join(",");
                    }
                    return pair;
                })
                .join(";");
            return {
                ...prevMaterial,
                [name]: {
                    ...prevMaterial[name],
                    values: newValues,
                },
            };
        });
    };

    const addPropertyTableRow = (name) => {
        setMaterial((prevMaterial) => {
            return {
                ...prevMaterial,
                [name]: {
                    ...prevMaterial[name],
                    values: prevMaterial[name].values + ";0.0,0.0",
                },
            };
        });
    };

    const saveMaterial = () => {
        const newMaterialDB = {
            ...materialDB,
            materialList: materialDB.materialList.map((m) =>
                m.uuid === material.uuid ? material : m
            ),
        };
        setMaterialDB(newMaterialDB);
        handleClose();
    };

    const renderPropertyTable = (label, propertyName, unit) => {
        const [error, setError] = useState("");

        const handleImportCSV = async (event) => {
            setError("");
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const content = e.target.result;
                    const rows = content
                        .split("\n")
                        .filter((row) => !row.startsWith("#"));
                    const data = rows.map((row) => row.split(","));
                    if (
                        data.every(
                            (row) =>
                                row.length === 2 &&
                                row.every((value) => !isNaN(parseFloat(value)))
                        )
                    ) {
                        const newValues = data
                            .map((row) => row.join(","))
                            .join(";");
                        setMaterial((prevMaterial) => ({
                            ...prevMaterial,
                            [propertyName]: {
                                ...prevMaterial[propertyName],
                                values: newValues,
                            },
                        }));
                    } else {
                        setError(
                            "Error: CSV file must have exactly two columns of numbers."
                        );
                    }
                };
                reader.readAsText(file);
            }
        };

        const handleDeleteRow = (index) => {
            setMaterial((prevMaterial) => {
                const rows = prevMaterial[propertyName].values.split(";");
                rows.splice(index, 1); // Remove the row
                return {
                    ...prevMaterial,
                    [propertyName]: {
                        ...prevMaterial[propertyName],
                        values: rows.join(";"),
                    },
                };
            });
        };

        return (
            <Accordion sx={{ mb: 2 }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    {`${label} (${unit})`}
                </AccordionSummary>
                <AccordionDetails>
                    {error && <div style={{ color: "red" }}>{error}</div>}
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Temperature (K)</TableCell>
                                <TableCell>Value ({unit})</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {material[propertyName].values
                                .split(";")
                                .slice(0, -1)
                                .map((pair, index) => {
                                    const [temperature, value] =
                                        pair.split(",");
                                    return (
                                        <TableRow
                                            key={`${propertyName}-${index}`}
                                        >
                                            <TableCell>
                                                <TextField
                                                    value={temperature}
                                                    onChange={(e) =>
                                                        handlePropertyTableChange(
                                                            propertyName,
                                                            index,
                                                            0,
                                                            e.target.value
                                                        )
                                                    }
                                                    type="number"
                                                    InputProps={{
                                                        inputProps: { min: 0 },
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    value={value}
                                                    onChange={(e) =>
                                                        handlePropertyTableChange(
                                                            propertyName,
                                                            index,
                                                            1,
                                                            e.target.value
                                                        )
                                                    }
                                                    type="number"
                                                    InputProps={{
                                                        inputProps: { min: 0 },
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={() =>
                                                        handleDeleteRow(index)
                                                    }
                                                    size="small"
                                                >
                                                    <DeleteOutlineOutlinedIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            <TableRow>
                                <TableCell colSpan={3}>
                                    <Button
                                        onClick={() =>
                                            addPropertyTableRow(propertyName)
                                        }
                                    >
                                        Add Row
                                    </Button>
                                    <input
                                        accept=".csv"
                                        style={{ display: "none" }}
                                        id="raised-button-file"
                                        multiple
                                        type="file"
                                        onChange={handleImportCSV}
                                    />
                                    <label htmlFor="raised-button-file">
                                        <Button component="span">
                                            Import CSV
                                        </Button>
                                    </label>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </AccordionDetails>
            </Accordion>
        );
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Edit Material</DialogTitle>
            <DialogContent>
                <TextField
                    label="Name"
                    name="name"
                    value={material.name}
                    onChange={handleMaterialChange}
                    fullWidth
                    margin="dense"
                    sx={{ mb: 2 }}
                />
                <TextField
                    label="Comment"
                    name="comment"
                    value={material.comment}
                    onChange={handleMaterialChange}
                    fullWidth
                    margin="dense"
                    sx={{ mb: 2 }}
                />
                <TextField
                    label="Density (kg/m³)"
                    name="density"
                    value={material.density}
                    onChange={handleMaterialChange}
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    fullWidth
                    margin="dense"
                    sx={{ mb: 2 }}
                />
                {renderPropertyTable(
                    "Specific Heat Capacity",
                    "specificHeatCapacity",
                    "J/kg·K"
                )}
                <TextField
                    label="Melting Heat (J/kg)"
                    name="meltingHeat"
                    value={material.meltingHeat}
                    onChange={handleMaterialChange}
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    fullWidth
                    margin="dense"
                    sx={{ mb: 2 }}
                />
                <TextField
                    label="Melting Temperature (K)"
                    name="meltingTemperature"
                    value={material.meltingTemperature}
                    onChange={handleMaterialChange}
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    fullWidth
                    margin="dense"
                    sx={{ mb: 2 }}
                />
                {renderPropertyTable("Emissivity", "emissivity", "")}
                {renderPropertyTable(
                    "Heat Conductivity",
                    "heatConductivity",
                    "W/m·K"
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={saveMaterial}>Save</Button>
            </DialogActions>
        </Dialog>
    );
};

const MaterialPicker = ({ geometry, onUpdateGeometry }) => {
    const { firebaseStorage } = useFirebase();
    const [materialDB, setMaterialDB] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedMaterial, setSelectedMaterial] = useState(null);
    const [openManagementDialog, setOpenManagementDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const [localGeometry, setLocalGeometry] = useState({
        ...geometry,
        PartIdToName: geometry.PartIdToName || {},
        MaterialsDict: geometry.MaterialsDict || {},
        PartIdField: geometry.PartIdField || null,
        IsAblative: geometry.IsAblative || false,
        IsDemisable: geometry.IsDemisable || false,
    });
    const [selectedField, setSelectedField] = useState(
        localGeometry.PartIdField
    );

    useEffect(() => {
        const materialDatabaseRef = ref(
            firebaseStorage,
            "material_database.json"
        );
        getDownloadURL(materialDatabaseRef)
            .then((url) => {
                fetch(url)
                    .then((response) => response.json())
                    .then((data) => {
                        const updatedData = {
                            ...data,
                            materialList: data.materialList.map((material) => ({
                                ...material,
                                uuid: crypto.randomUUID(),
                            })),
                        };
                        setMaterialDB(updatedData);
                    })
                    .catch((fetchError) =>
                        console.error(
                            "Error fetching material database:",
                            fetchError
                        )
                    );
            })
            .catch((error) => {
                if (error.code === "storage/object-not-found") {
                    uploadString(
                        materialDatabaseRef,
                        JSON.stringify(defaultMaterialDB)
                    )
                        .then(() => {
                            console.log(
                                "Material database uploaded successfully."
                            );
                            const updatedData = {
                                ...defaultMaterialDB,
                                materialList:
                                    defaultMaterialDB.materialList.map(
                                        (material) => ({
                                            ...material,
                                            uuid: crypto.randomUUID(),
                                        })
                                    ),
                            };
                            setMaterialDB(updatedData);
                        })
                        .catch((uploadError) =>
                            console.error(
                                "Failed to upload material database:",
                                uploadError
                            )
                        );
                } else {
                    console.error(
                        "Failed to retrieve material database:",
                        error
                    );
                }
            });
    }, [firebaseStorage]);

    const applyChanges = useCallback(() => {
        onUpdateGeometry(localGeometry);
    }, [localGeometry, onUpdateGeometry]);

    const handleOpenManagementDialog = () => {
        setOpenManagementDialog(true);
    };

    const handleCloseManagementDialog = () => {
        setOpenManagementDialog(false);
        setSelectedRow(null);
        setIsEditing(false);

        const materialDatabaseRef = ref(
            firebaseStorage,
            "material_database.json"
        );
        uploadString(materialDatabaseRef, JSON.stringify(materialDB))
            .then(() => console.log("Material database updated successfully."))
            .catch((error) => {
                console.error("Failed to update material database:", error);
                setSnackbarOpen(true);
            });
    };

    const handleOpenDetailsDialog = (material) => {
        setSelectedMaterial(material);
        setOpenDialog(true);
    };

    const handleDuplicateMaterial = () => {
        if (selectedRow != null) {
            let baseName = materialDB.materialList[selectedRow].name;
            let newName = baseName + " Copy";
            let copyNumber = 1;

            // Check if the newName already exists and increment copyNumber until a new name is found
            while (
                materialDB.materialList.some(
                    (material) => material.name === newName
                )
            ) {
                copyNumber++;
                newName = `${baseName} Copy ${copyNumber}`;
            }

            const newMaterial = {
                ...materialDB.materialList[selectedRow],
                name: newName,
                uuid: crypto.randomUUID(),
            };
            const newMaterialList = [...materialDB.materialList, newMaterial];
            setMaterialDB({ ...materialDB, materialList: newMaterialList });
        }
    };

    const handleDeleteMaterial = () => {
        if (
            selectedRow != null &&
            !isMaterialInDefaultDB(materialDB.materialList[selectedRow])
        ) {
            const newMaterialList = materialDB.materialList.filter(
                (_, index) => index !== selectedRow
            );
            setMaterialDB({ ...materialDB, materialList: newMaterialList });
            setSelectedRow(null); // Reset selection after deletion
        }
    };

    const handleCheckboxChange = (event, index) => {
        if (event.target.checked) {
            setSelectedRow(index);
            setIsEditing(false);
        } else {
            setSelectedRow(null);
        }
    };

    const handleEditMaterial = () => {
        setIsEditing(true);
    };

    const isMaterialInDefaultDB = (material) => {
        return defaultMaterialDB.materialList.some(
            (defaultMaterial) => defaultMaterial.name === material.name
        );
    };

    return (
        <div>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
            >
                <Alert
                    onClose={() => setSnackbarOpen(false)}
                    severity="error"
                    sx={{ width: "100%" }}
                >
                    Failed to update material database. Please try again.
                </Alert>
            </Snackbar>
            <MaterialDetailsDialog
                openDialog={openDialog}
                handleCloseDialog={() => {
                    setOpenDialog(false);
                    setSelectedMaterial(null);
                }}
                selectedMaterial={selectedMaterial}
            />
            <Dialog
                open={openManagementDialog}
                onClose={handleCloseManagementDialog}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>Materials Database Management</DialogTitle>
                <DialogContent>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox"></TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Comment</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {materialDB?.materialList
                                    .sort((a, b) =>
                                        a.name.localeCompare(b.name)
                                    )
                                    .map((material, index) => (
                                        <TableRow key={index}>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    onChange={(event) =>
                                                        handleCheckboxChange(
                                                            event,
                                                            index
                                                        )
                                                    }
                                                    checked={
                                                        selectedRow === index
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell>
                                                {material.name}
                                            </TableCell>
                                            <TableCell>
                                                {material.comment}
                                            </TableCell>
                                            <TableCell>
                                                <IconButton
                                                    onClick={() =>
                                                        handleOpenDetailsDialog(
                                                            material
                                                        )
                                                    }
                                                >
                                                    <HelpOutlineOutlinedIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleDuplicateMaterial}
                        color="primary"
                        variant="contained"
                        disabled={selectedRow === null}
                        startIcon={<FileCopyOutlinedIcon />}
                    >
                        Duplicate
                    </Button>
                    <Button
                        onClick={handleEditMaterial}
                        color="secondary"
                        variant="contained"
                        disabled={
                            selectedRow === null ||
                            isMaterialInDefaultDB(
                                materialDB.materialList[selectedRow]
                            )
                        }
                        startIcon={<EditOutlinedIcon />}
                    >
                        Edit
                    </Button>
                    <Button
                        onClick={handleDeleteMaterial}
                        color="error"
                        variant="contained"
                        disabled={
                            selectedRow === null ||
                            isMaterialInDefaultDB(
                                materialDB.materialList[selectedRow]
                            )
                        }
                        startIcon={<DeleteOutlineOutlinedIcon />}
                    >
                        Delete
                    </Button>
                    <Button
                        onClick={handleCloseManagementDialog}
                        color="primary"
                        variant="outlined"
                        startIcon={<CloseIcon />}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            {selectedRow !== null && (
                <MaterialEditForm
                    open={isEditing}
                    handleClose={() => setIsEditing(false)}
                    materialDB={materialDB}
                    setMaterialDB={setMaterialDB}
                    selectedMaterial={materialDB.materialList[selectedRow]}
                />
            )}
            <div className="flex justify-between items-center w-full">
                <label className="font-medium text-gray-700 font-roboto mr-3 min-w-[165px] max-w-[175px]">
                    Materials assignment
                </label>
                <button
                    className={`${styles.dashboardModalButton} text-center`}
                    onClick={applyChanges}
                >
                    <div className="flex items-center justify-center space-x-2 h-full">
                        <div>
                            <SaveOutlinedIcon fontSize="medium" />
                        </div>
                        <label className="font-roboto">Save Changes</label>
                    </div>
                </button>
            </div>
            <div className="flex my-4">
                <button
                    className={`${styles.dashboardModalButton} text-center`}
                    onClick={handleOpenManagementDialog}
                >
                    <div className="flex items-center justify-center space-x-2 h-full">
                        <div>
                            <StorageOutlinedIcon fontSize="medium" />
                        </div>
                        <label className="font-roboto">
                            Materials database management
                        </label>
                    </div>
                </button>
            </div>
            <div>
                <Toggle
                    id="IsAblative"
                    label="Ablative materials"
                    database={localGeometry}
                    setDatabase={setLocalGeometry}
                />
                <Toggle
                    id="IsDemisable"
                    label="Demisable spacecraft"
                    database={localGeometry}
                    setDatabase={setLocalGeometry}
                />
                <div className="flex items-center my-4">
                    <label className="font-medium mr-3 min-w-[160px] text-inherit">
                        Part id. field name
                    </label>
                    <TextField
                        select
                        fullWidth
                        variant="outlined"
                        value={selectedField}
                        onChange={(e) => {
                            const value = e.target.value;
                            setSelectedField(value);
                            setLocalGeometry((prev) => ({
                                ...prev,
                                PartIdField: value,
                            }));
                        }}
                    >
                        <MenuItem key="no-partidfield" value="-">
                            -
                        </MenuItem>
                        {Object.keys(geometry.CellDataCharacteristics).map(
                            (key) => (
                                <MenuItem key={key} value={key}>
                                    {key}
                                </MenuItem>
                            )
                        )}
                    </TextField>
                </div>
                <MaterialTable
                    materialDB={materialDB}
                    localGeometry={localGeometry}
                    setLocalGeometry={setLocalGeometry}
                    setOpenDialog={setOpenDialog}
                    setSelectedMaterial={setSelectedMaterial}
                    selectedField={selectedField}
                />
            </div>
        </div>
    );
};

export default MaterialPicker;
