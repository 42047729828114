// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LandingPageTitle_titlingFont__98aPh {
    margin: 0px;
    line-height: 1.375;
    letter-spacing: 0.025em;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}
`, "",{"version":3,"sources":["webpack://./src/components/Header/LandingPageTitle.module.css"],"names":[],"mappings":"AACI;IAAA,WAAgD;IAAhD,kBAAgD;IAAhD,uBAAgD;IAAhD,oBAAgD;IAAhD;AAAgD","sourcesContent":[".titlingFont {\n    @apply text-white tracking-wide m-0 leading-snug;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titlingFont": `LandingPageTitle_titlingFont__98aPh`
};
export default ___CSS_LOADER_EXPORT___;
