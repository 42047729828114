import { useEffect, useState } from "react";
import { useThree } from "@react-three/fiber";
import { SRGBColorSpace, TextureLoader, WebGLCubeRenderTarget } from "three";

import Sun from "./Sun";

const BackgroundScene = ({ lightDirection }) => {
    const [cubeTexture, setCubeTexture] = useState(null);

    const { gl, scene } = useThree();

    useEffect(() => {
        const loader = new TextureLoader();
        loader.load(
            `${process.env.PUBLIC_URL}/assets/starmap_g4k.jpg`,
            (texture) => {
                texture.colorSpace = SRGBColorSpace;
                const cubeRenderTarget = new WebGLCubeRenderTarget(
                    texture.image.height
                );
                cubeRenderTarget.fromEquirectangularTexture(gl, texture);
                setCubeTexture(cubeRenderTarget.texture);
            }
        );
    }, [gl]);

    useEffect(() => {
        if (cubeTexture) {
            scene.background = cubeTexture;
        }
    }, [cubeTexture]);

    return <Sun position={lightDirection.clone().multiplyScalar(15)} />;
};

export default BackgroundScene;
