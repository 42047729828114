// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ThreeDotsLoader_threeDotsLoader__r0BKG {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ThreeDotsLoader_threeDotsLoader__r0BKG > div {
    height: 0.5rem;
    width: 0.5rem;
    margin: 0 2px;
    background-color: rgba(0, 214, 252, 0.7);
    border: 1px solid rgba(0, 214, 252, 1);
    border-radius: 50%;
    box-shadow: 1px 1px 8px rgba(0, 214, 252, 0.3);
    animation: ThreeDotsLoader_threeDots__UzM\\+U 1.4s infinite;
}

.ThreeDotsLoader_threeDotsLoader__r0BKG > div:nth-child(1) {
    animation-delay: 0.2s;
}

.ThreeDotsLoader_threeDotsLoader__r0BKG > div:nth-child(2) {
    animation-delay: 0.4s;
}

.ThreeDotsLoader_threeDotsLoader__r0BKG > div:nth-child(3) {
    animation-delay: 0.6s;
}

@keyframes ThreeDotsLoader_threeDots__UzM\\+U {
    0%,
    80%,
    100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/ThreeDotsLoader/ThreeDotsLoader.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAGI;IAAA,cAAc;IAAd,aAAc;IACd,aAAa;IACb,wCAAwC;IACxC,sCAAsC;IACtC,kBAAkB;IAClB,8CAA8C;IAC9C;AANc;;AASlB;IACI,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI;;;QAGI,mBAAmB;IACvB;IACA;QACI,mBAAmB;IACvB;AACJ","sourcesContent":[".threeDotsLoader {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.threeDotsLoader > div {\n    @apply w-2 h-2;\n    margin: 0 2px;\n    background-color: rgba(0, 214, 252, 0.7);\n    border: 1px solid rgba(0, 214, 252, 1);\n    border-radius: 50%;\n    box-shadow: 1px 1px 8px rgba(0, 214, 252, 0.3);\n    animation: threeDots 1.4s infinite;\n}\n\n.threeDotsLoader > div:nth-child(1) {\n    animation-delay: 0.2s;\n}\n\n.threeDotsLoader > div:nth-child(2) {\n    animation-delay: 0.4s;\n}\n\n.threeDotsLoader > div:nth-child(3) {\n    animation-delay: 0.6s;\n}\n\n@keyframes threeDots {\n    0%,\n    80%,\n    100% {\n        transform: scale(0);\n    }\n    40% {\n        transform: scale(1);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"threeDotsLoader": `ThreeDotsLoader_threeDotsLoader__r0BKG`,
	"threeDots": `ThreeDotsLoader_threeDots__UzM+U`
};
export default ___CSS_LOADER_EXPORT___;
